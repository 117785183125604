import React, { lazy, Suspense }  from "react";
const FooterSocialBox = lazy(() => import('./FooterSocialBox'));
const FooterPartners = lazy(() => import('./FooterPartners'));

const Footer = () => {
  return (
  	<div id="footer">
			<Suspense fallback={<div>Loading...</div>}>
        <>
					<FooterSocialBox/>
					<FooterPartners/>
        </>
      </Suspense>
		</div>
  );
}

export default Footer;
