import React, { useContext } from 'react';
import { Sidebar } from '../../../common/styles/CasinosStyles'
import { CasinoContext } from '../../../context/CasinoContext';
import { NavLink as RRNavLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const CitySidebar = () =>{

  const { cities } = useContext(CasinoContext);

  return(
    <Sidebar>
      <li>
        <NavLink tag={RRNavLink} to="/en/casinos" activeClassName="active" exact >
          All Gaming rooms
          <div className="line"></div>
        </NavLink>
      </li>
      {cities &&  cities.map((item, index)=>
        <li key={index}>
          <NavLink tag={RRNavLink} to={`/en/casinos/${item.name_en}`} activeClassName="active" exact >
            {item.name_en}
            <div className="line"></div>
          </NavLink>
        </li>
      )}
    </Sidebar>
  )
}

export default CitySidebar;