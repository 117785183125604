import React, { lazy, Suspense } from 'react';
import { PageWrapper, ContentWrapper } from '../../common/styles/CasinosStyles';
import TailSpinLoading from '../../common/loading/TailSpinLoading';
import CitySidebar from '../components/casino/CitySidebar';
import { CasinoContext } from '../../context/CasinoContext';
import { useHttpsStore } from '../../helpers/https';
import SingleCasino from '../components/casino/SingleCasino';
const TopWinsSlider = lazy(() => import('../components/slider/TopWinsSlider'));
const SingleCasinoMap = lazy(() => import('../../common/maps/SingleCasinoMap'));

const SingleCasinoPageEn = props =>{
  
  const [isLoading, casinos] = useHttpsStore(
    "https://win-cor.winbet-bg.com/api/casinos",
    "casinos",
    "casinosDate"
  );
  const [, cities] = useHttpsStore(
    "https://win-cor.winbet-bg.com/api/cities",
    "city",
    "cityDate"
  );

  const casinoIndex = props.match.params.id-1;

  if(isLoading)
    return(
      <TailSpinLoading back="#333"/>
    );
  
  return(
    <CasinoContext.Provider value={{ casinos: casinos, cities: cities, choosenCity: props.match.params.city, casinoIndex: casinoIndex }}>
      <PageWrapper>
        <div className="container">
            <ContentWrapper>
              <CitySidebar/>
              <SingleCasino/>
            </ContentWrapper>
        </div>
      </PageWrapper>
      <Suspense fallback={<TailSpinLoading back="#333"/>}>
        <TopWinsSlider/>
        <SingleCasinoMap
          containerElement={<div style={{ height: `360px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Suspense>
    </CasinoContext.Provider>
  )
}

export default SingleCasinoPageEn;