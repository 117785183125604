import styled from 'styled-components';

export const SliderBorder = styled.div`
  background-color: ${props => props.color};
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  top: 78px;
  z-index: 100;
`;

export const MainSliderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 99vh;
  background: #000;
  .slick-dots li button{
    background: #fff;
    width: 12px;
    height: 12px;
    border-radius: 100px;
  }
  .slick-dots li:hover{
    opacity: .8;
  }
  .slick-dots li button:before{
    content: '';
    background: transparent;
  }
  .slick-dots li{
    width: 12px;
    height: 12px;
    margin: 0 2px!important;
    transition: .2s ease-in-out;
    opacity: .4;
  }
  .slick-dots li.slick-active{
    opacity: 1;
  }
  .overlay-content{
    margin-bottom: 150px;
  }
  .slider-border{
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    top: 78px;
    z-index: 100;
  }
  .slide img{
    width: 100%;
  }
  .slider-front-img{
    max-width: 600px;
  }
  .slick-slide{
    visibility: hidden!important;
  }
  .slick-slide.slick-active.slick-current{
    visibility: visible!important;
  }
  .title-white-line{
    margin-left: 0;
    margin-bottom: 4vh;
  }
  .slider-overlay h2{
    font-size: 40px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    max-width: 500px;
    font-weight: 900;
    margin: 0 0 2vh;
  }
  .slider-overlay p{
    max-width: 450px;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.43;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 4vh;
  }
  .main-slider{
    position: relative;
    overflow: hidden;
    max-height: 100vh;
    background: #000;
  }
  .slide > img{
    display: inline-block!important;
  }
  .slider-overlay{
    position: absolute;
    width: auto;
    height: 100%;
    top: 0px;
    left: 12vw;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .slider-register{
    display: inline-block;
    padding: 12px 28px;
    border: solid 2px;
    border-radius: 4px;
    font-size: 17px;
    font-weight: 600;
    margin-right: 8px;
    cursor: pointer;
    min-width: 174px;
    text-align: center;
    transition: ease .2s;
  }
  .slider-log-in{
    display: inline-block;
    padding: 12px 28px;
    border-radius: 4px;
    border: solid 2px;
    font-size: 17px;
    min-width: 174px;
    text-align: center;
    cursor: pointer;
    transition: ease .2s;
  }
  .slider-log-in:hover, .slider-register:hover{
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
  }
  .slider-log-in:hover{
    background-color: #fff;
    color: rgba(49, 49, 49, 1);
  }
  .slider-register:hover{
    background-color: #940f14;
    color: #fff;
    border: solid 2px #940f14;
  }
  .slick-slider{
    position: static;
  }
  .slick-dots{
    bottom: 30px;
  }
  .slick-dots li button:before{
    color: #fff;
    font-size: 16px;
  }
  .slick-dots li.slick-active button:before{
    color: #fff!important;
  }
  .slick-dots li{
    margin: 0;
  }
  .emty-slider-front-img{
    display: none!important;
  }
  @media (min-width: 1800px){
    .slider-overlay{
      left: 17vw;
    }
    .overlay-content{
      margin-bottom: 220px;
    }
  }
  @media (min-width: 2100px){
    .slider-overlay{
      left: 21vw;
    }
    .overlay-content{
      margin-bottom: 220px;
    }
  }
  @media (max-width: 1550px){
    .slider-overlay h2{
      font-size: 30px;
      max-width: 360px;
      margin: 0 0 2vh;
    }
    .slider-overlay p {
      max-width: 390px;
      font-size: 15px;
    }
    .slider-register, .slider-log-in{
      min-width: 150px;
      font-size: 15px;
      padding: 10px 25px;
    }
    .slider-front-img {
      max-width: 500px;
    }
    .overlay-content{
      margin-bottom: 110px;
    }
  }
  @media (max-width: 1400px){
    .overlay-content{
      margin-bottom: 70px;
    }
  }
  @media (max-width: 1300px){
    .slider-overlay{
      left: 11vw;
    }
    .overlay-content{
      margin-bottom: 40px;
    }
  }
  @media (max-width: 1250px){
    .slider-front-img {
      max-width: 450px;
    }
  }
  @media (max-width: 1100px){
    .slider-overlay img{
      width: 400px;
      margin: 5vh 0 2vh -4px;
    }
    .slider-overlay h2{
      font-size: 26px;
      max-width: 280px;
      margin: 0 0 2vh;
    }
    .slider-overlay p {
      max-width: 330px;
      font-size: 14px;
      margin-bottom: 3vh;
    }
    .slider-register, .slider-log-in{
      min-width: 150px;
      font-size: 14px;
      padding: 10px 25px;
    }
    .slider-overlay{
      left: 9vw;
    }
    .slick-dots li button:before{
      font-size: 14px;
    }
    .slick-dots li, .slick-dots li button, .slick-dots li button:before{
      width: 17px;
      height: 17px;
      padding: 0;
    }
  }
  @media (max-width: 900px){
    .slider-overlay img{
      width: 100px;
      margin: 21px 0 10px -3px;
    }
    .slider-overlay h2{
      font-size: 17px;
      max-width: 200px;
      margin: 0 0 10px;
    }
    .slider-overlay p {
      max-width: 275px;
      font-size: 13px;
      margin-bottom: 15px;
    }
    .slider-register, .slider-log-in{
      min-width: 120px;
      font-size: 13px;
      padding: 8px 17px;
    }
    .main-slider .title-white-line{
      margin-bottom: 10px;
    }
  }
  @media (max-width: 700px){
    .slider-overlay img{
      margin: 10vh 0 15px -2px;
    }
    .slider-overlay p {
      display: none;
    }
    .slider-overlay h2 {
      margin-bottom: 30px;
    }
    .main-slider .title-white-line{
      display: none;
    }
  }
  @media (max-width: 550px){
    .main-slider .slick-dots{
      bottom: 15px;
    }
  }
  @media (max-width: 500px){
    .slider-overlay img{
      margin: 7vh 0 10px -2px;
      width: 80px;
    }
    .slider-overlay h2 {
      font-size: 14px;
      max-width: 145px;
    }
    .slider-register, .slider-log-in{
      display: none;
    }
  }
`;

export const OnlineJackpotBox = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .main-mystery-box-mega{
    position: relative;
    width: 330px;
  }
  .main-mystery-box-img{
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
  .custom-count-online{
    display: flex;
    align-items: center;
    font-weight: 900;
    position: absolute;
    color: #e6e6e6;
    font-size: 26px;
    top: 31px;
    right: 45px;
    justify-content: center;
    width: 210px;
    text-align: center
  }
  .main-mystery-box-currency{
    width: 10px!important;
    display: inline-block;
    margin-left: 12px;
    opacity: .6;
  }
  @media (max-width: 1400px){
    margin-top: 15px;
    .main-mystery-box-mega{
      width: 280px;
    }
    .custom-count-online{
      top: 31px;
      font-size: 24px;
      right: 35px;
    }
    .main-mystery-box-currency{
      width: 8px!important;
      margin-left: 8px;
    }
  }
`;

export const ProgressiveJackpotBox = styled.div`
  .slick-slide{
    visibility: visible!important;
  }
  .main-mystery-box{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .main-mystery-box-mega{
    position: relative;
    width: 330px;
  }
  .main-mystery-box-img{
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
  .custom-count-ra{
    top: 34px;
    font-size: 26px;
    right: 45px;
    color: #e6e6e6;
    position: absolute;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    text-align: center;
  }
  .custom-count-versai{
    font-size: 26px;
    right: 55px;
    top: 27px;
    color: #e6e6e6;
    position: absolute;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    text-align: center;
  }
  .custom-count-burning{
    font-size: 26px;
    right: 55px;
    top: 46px;
    color: #e6e6e6;
    position: absolute;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    text-align: center;
  }
  .main-mystery-box-currency{
    width: 10px!important;
    display: inline-block!important;
    margin-left: 12px;
    opacity: .6;
  }
  @media (max-width: 1500px){
    .main-mystery-box{
      margin-top: 12px;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 1400px){
    .main-mystery-box-mega{
      width: 280px;
    }
    .custom-count-ra{
      top: 33px;
      font-size: 24px;
      right: 35px;
    }
    .custom-count-versai{
      font-size: 24px;
      right: 35px;
      top: 26px;
    }
    .custom-count-burning{
      font-size: 24px;
      right: 35px;
      top: 45px;
    }
    .main-mystery-box-currency{
      width: 8px!important;
      margin-left: 8px;
    }
  }
  @media (max-width: 1249px){
    .main-mystery-box{
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
`;

export const MainMegaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33vh;
  flex-direction: column;
  border-bottom: solid 1px ${props => props.border};
  .main-mystery-box-currency {
    width: 11px!important;
    display: inline-block!important;
    margin-left: 12px;
    opacity: .6;
  }
  .custom-count-mega, .custom-count-super{
    top: 17px;
    color: #e6e6e6;
    position: absolute;
    font-size: 29px;
    font-weight: 900;
    width: 210px;
    margin: 0 auto;
    text-align: center;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-mystery-box-mega .main-mystery-box-img {
    max-width: 330px;
    margin: 0 auto;
    width: 100%;
  }
  .main-mystery-box-super .main-mystery-box-img {
    max-width: 310px;
    margin: 0 auto;
    width: 100%;
  } 
  .main-mystery-box-mega, .main-mystery-box-super{
    position: relative;
    margin-bottom: 5px;
    width: 330px;
  }
  .main-mystery-box-img-title{
    max-width: 380px!important;
  }
  @media (max-width: 1500px){
    height: 34vh;
    .main-mystery-box-img-title{
      max-width: 350px!important;
    }
  }
  @media (max-width: 1400px){
    height: 28vh;
    .main-mystery-box-img-title{
      max-width: 310px!important;
    }
    .main-mystery-box-currency {
      width: 9px!important;
      margin-left: 10px;
    }
    .custom-count-mega{
      top: 15px;
      font-size: 28px;
    }
    .custom-count-super{
      top: 17px;
      font-size: 28px;
    }
    .main-mystery-box-mega .main-mystery-box-img {
      max-width: 300px;
    }
    .main-mystery-box-super .main-mystery-box-img {
      max-width: 285px;
    } 
  }
`;

export const MainPikaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35vh;
  flex-direction: column;
  border-bottom: solid 1px ${props => props.border};
  .main-mystery-box-currency {
    width: 11px!important;
    display: inline-block!important;
    margin-left: 12px;
    opacity: .6;
  }
  .custom-count-splash {
    top: 29px;
    color: #e6e6e6;
    position: absolute;
    font-size: 29px;
    font-weight: 900;
    width: 210px;
    margin: 0 auto;
    text-align: center;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-count-heart, .custom-count-diamond, .custom-count-spade{
    top: 11px;
    color: #e6e6e6;
    position: absolute;
    font-size: 29px;
    font-weight: 900;
    width: 200px;
    margin: 0 auto;
    text-align: center;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-jackpotCards-box-splash .main-jackpotCards-box-img {
    max-width: 330px;
    margin: 0 auto;
    width: 100%;
  }
  .main-jackpotCards-box-heart .main-jackpotCards-box-img {
    max-width: 310px;
    margin: 0 auto;
    width: 100%;
  } 
  .main-jackpotCards-box-diamond .main-jackpotCards-box-img {
    max-width: 290px;
    margin: 0 auto;
    width: 100%;
  }
  .main-jackpotCards-box-spade .main-jackpotCards-box-img {
    max-width: 270px;
    margin: 0 auto;
    width: 100%;
  }
  .main-jackpotCards-box-splash, .main-jackpotCards-box-heart, .main-jackpotCards-box-diamond, .main-jackpotCards-box-spade{
    position: relative;
    margin-bottom: 5px;
    width: 330px;
  }
  @media (max-width: 1400px){
    height: 30vh;
    .main-mystery-box-currency {
      width: 9px!important;
      margin-left: 10px;
    }
    .custom-count-splash {
      top: 26px;
      font-size: 28px;
    }
    .custom-count-heart, .custom-count-diamond, .custom-count-spade{
      top: 11px;
      font-size: 28px;
    }
    .main-jackpotCards-box-splash .main-jackpotCards-box-img {
      max-width: 300px;
    }
    .main-jackpotCards-box-heart .main-jackpotCards-box-img {
      max-width: 285px;
    } 
    .main-jackpotCards-box-diamond .main-jackpotCards-box-img {
      max-width: 270px;
    }
    .main-jackpotCards-box-spade .main-jackpotCards-box-img {
      max-width: 250px;
    }
  }
`;

export const SubMegaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  .main-mystery-box-currency{
    width: 10px!important;
    display: inline-block!important;
    margin-left: 12px;
    opacity: .6;
  }
  .custom-count-mega{
    top: 62px;
    font-size: 26px;
    right: 45px;
    color: #e6e6e6;
    position: absolute;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    text-align: center;
  }
  .main-mystery-box-img{
    max-width: 300px!important;
    margin: 0 auto;
    width: 100%;
  }
  .main-mystery-box-mega{
    position: relative;
    width: 330px;
  }
  @media (max-width: 1400px){
    .main-mystery-box-mega{
      width: 280px;
    }
    .custom-count-mega{
      font-size: 24px;
      right: 35px;
      top: 60px;
    }
    .main-mystery-box-currency{
      width: 8px!important;
      margin-left: 8px;
    }
  }
`;

export const SubPikaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0 10px;
  .main-mystery-box-currency{
    width: 10px!important;
    display: inline-block!important;
    margin-left: 12px;
    opacity: .6;
  }
  .custom-count-splash{
    top: 27px;
    font-size: 26px;
    right: 45px;
    color: #e6e6e6;
    position: absolute;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    text-align: center;
  }
  .main-jackpotCards-box-img{
    max-width: 300px!important;
    margin: 0 auto;
    width: 100%;
  }
  .main-jackpotCards-box-splash{
    position: relative;
    width: 330px;
  }
  @media (max-width: 1400px){
    .main-jackpotCards-box-splash{
      width: 280px;
    }
    .custom-count-splash{
      font-size: 24px;
      right: 35px;
      top: 26px;
    }
    .main-mystery-box-currency{
      width: 8px!important;
      margin-left: 8px;
    }
  }
`;

export const JackpotWrapper = styled.div`
  position: absolute;
  top: 78px;
  right: 12vw;
  height: 100vh;
  width: 440px;
  background-image: linear-gradient(to top, ${props => props.back}, rgba(23,7,57,0));
  .odometer-digit{
    font-family: 'Roboto', sans-serif;
  }
  @media (min-width: 1800px){
    right: 17vw;
  }
  @media (min-width: 2100px){
    right: 21vw;
  }
  @media (max-width: 1400px){
    width: 400px;
    right: 12vw;
  }
  @media (max-width: 1200px){
    right: 10vw;
  }
`;