import React from 'react';
import Slider from 'react-slick';
import '../../../../node_modules/slick-carousel/slick/slick.css';
import '../../../../node_modules/slick-carousel/slick/slick-theme.css';
import { useHttpsStore, useHttpsInterval } from '../../../helpers/https';
import { MainSliderContext } from '../../../context/MainSliderContext';
import { JackpotContext } from '../../../context/JackpotContext';
import JackpotBox from '../../../common/jackpots/slider/JackpotBox';
import MainSliderBackground from './MainSliderBackground';
import { MainSliderWrapper, SliderBorder} from '../../../common/styles/MainSliderStyles';
import TailSpinLoading from '../../../common/loading/TailSpinLoading';
import MainSliderContent from './MainSliderContent';

const MainSlider = () =>{

  const[ isLoading, fetchedData] = useHttpsStore(
    "https://win-cor.winbet-bg.com/api/bg/home-sliders",
    "mainSlider",
    "mainSliderDate"
  );
  const[fetchedJackpotData] = useHttpsInterval(
    "https://win-cor.winbet-bg.com/api/home-sliders/prepared-data"
  );  

  const sliderData = fetchedData !== null ? fetchedData : [];
  
  const slides = sliderData.map((data, index) => {
      if(data.is_deleted === 0) {
        if(data.has_mystery === 0)
          return(          
            <div className="slide" key={index}>
              <MainSliderContext.Provider value={{ sliderData: data }} >
                <SliderBorder color={data.border_color_slider}/>
                <MainSliderBackground/>
                <MainSliderContent/>
              </MainSliderContext.Provider>
            </div>
          );
          return(
            <div className="slide" key={index}>
              <MainSliderContext.Provider value={{ sliderData: data }} >
                <SliderBorder color={data.border_color_slider}/>
                <MainSliderBackground/>
                <MainSliderContent />
                <JackpotContext.Provider value={{ jackpotData: fetchedJackpotData }}>
                  <JackpotBox/>
                </JackpotContext.Provider>
              </MainSliderContext.Provider>
            </div>
          );
      }
      return(
        null
      );
    })
 
    var settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 6000,
      infinite: true,
      speed: 400,
      fade: true,
      cssEase: 'linear',
      dots: true
    };

  if(isLoading)
    return(
      <TailSpinLoading back="#333"/>
    );

  return(
    <MainSliderWrapper>
      <Slider {...settings} className="full-width-slider">
        {slides}
      </Slider>
    </MainSliderWrapper>
  );
}

export default MainSlider;

