import React from "react";
import Header from '../en/components/header/Header';
import Footer from '../en/components/footer/Footer';
import styled from 'styled-components';

const MainLayoutWrapper = styled.div`
  .navbar{
    background: linear-gradient(180deg, rgba(20,26,26,1) 0%, rgba(35,35,37,1) 100%);
    height: 80px;
    padding: 0!important;
    border-bottom: solid 1px #000;
  }
`

const MainLayout = props => {
	
  return (
    <MainLayoutWrapper>
      <Header/>
      <main>
        {props.children}
      </main>
      <Footer/>
    </MainLayoutWrapper>
  );
}

export default MainLayout;