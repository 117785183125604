import React, { useContext } from 'react';
import { MainSliderContext } from '../../../context/MainSliderContext';

const MainSliderBackground = () =>{

  const  { sliderData }  = useContext(MainSliderContext);
  const extension = sliderData.background_file_name.substr(sliderData.background_file_name.length - 3)
  if(extension === "mp4") {
    return(
    	<video width="100%" autoPlay muted loop>
  			<source src={"https://win-cor.winbet-bg.com/storage/" + sliderData.background_file_name} type="video/mp4"/>
			</video>
    )
  }
  return(
			<img src={"https://win-cor.winbet-bg.com/storage/" + sliderData.background_file_name} alt='winbet'/>
    )
}

export default MainSliderBackground;