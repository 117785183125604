import React, { useContext } from 'react';
import { CasinoContext } from '../../../context/CasinoContext';
import { SingleCasinoWrapper, CasinoListTitle, SingleCasinoContent, SingleCasinoJackpot, SingleCasinoFlexBox, SingleCasinoPromotionList } from '../../../common/styles/CasinosStyles';
import { addImageToGallery } from '../../../helpers/functions';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import tempImg from '../../../images/testImg.png';

const SingleCasino = () =>{

  const { casinos, casinoIndex } = useContext(CasinoContext);
  const [ images ] = addImageToGallery(casinos, casinoIndex)

  return(
<SingleCasinoWrapper>
      <CasinoListTitle single>
        <h4>{`Winbet gaming room ${casinos[casinoIndex].name}`}</h4>
        <div className="title-line"></div>
      </CasinoListTitle>
      <SingleCasinoFlexBox>
        <SingleCasinoContent>
          <div className="content-block">{`${casinos[casinoIndex].city_name_en}, ${casinos[casinoIndex].address_en}`}</div>
          <div className="content-block">
            <div className="text" dangerouslySetInnerHTML={{__html: casinos[casinoIndex].description_en}} />
          </div>
          <div className="content-block">
            <CasinoListTitle single mb secondary>
              <h4>Promotions</h4>
              <div className="title-line"></div>
            </CasinoListTitle>
            <SingleCasinoPromotionList>
            {
              casinos[casinoIndex].promotions.length > 0 &&
              casinos[casinoIndex].promotions.map((promo, index) =>
                <li key={index} dangerouslySetInnerHTML={{__html: promo.description_en}} />
              )
            }
            </SingleCasinoPromotionList>
          </div>
          <div className="content-block">
            <CasinoListTitle single mb secondary>
              <h4>Gallery</h4>
              <div className="title-line"></div>
            </CasinoListTitle>
            <ImageGallery items={images} />
          </div>
        </SingleCasinoContent>
        <SingleCasinoJackpot>
          <img src={tempImg} alt="winbet"/>
        </SingleCasinoJackpot>
      </SingleCasinoFlexBox>
      <div className="content-block">
        <CasinoListTitle single mb secondary>
          <h4>Virtual walk</h4>
          <div className="title-line"></div>
        </CasinoListTitle>
        <iframe src="https://www.google.com/maps/embed?pb=!4v1574414251218!6m8!1m7!1sCAoSLEFGMVFpcFBRaHBCbzQtU2NsaEJCWEUwanQyRmRMN3oyX2RMbV9tUXc5bmFa!2m2!1d42.658242148776!2d23.315748915157!3f130.76!4f0!5f0.7820865974627469" title="winbet"></iframe>
      </div>
    </SingleCasinoWrapper>
  )
}

export default SingleCasino;