import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import logo from "../../../images/winbet-logo.png";
import { MainMenuWrapper } from '../../../common/styles/HeaderStyles';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink
  } from 'reactstrap';
import LangBar from './LangBar';

const MainMenu = () =>{

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () =>{
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  return (
    <MainMenuWrapper>
      <Navbar color="custom-header" expand="lg">
        <div className="container">
          <Link className="navbar-brand" to="/bg">
            <img src={logo}  alt="winbet"/>
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink tag={RRNavLink} className="nav-link" activeClassName="active" exact to="/bg">Начало</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RRNavLink} className="nav-link" activeClassName="active" to="/bg/casinos">Игрални зали</NavLink>
              </NavItem>
              <NavItem>
                <a className="nav-link" href="https://winbet.bg/bg" target="_blank" rel="noopener noreferrer">Онлайн казино</a>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/bg">Спортни барове</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/bg">За нас</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/bg">Контакти</Link>
              </NavItem>
            </Nav>
          </Collapse>
          <LangBar/>
        </div>
      </Navbar>
    </MainMenuWrapper>
  );
}
export default MainMenu;





            