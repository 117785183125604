import React, { lazy, Suspense } from 'react';
import { PageWrapper, ContentWrapper } from '../../common/styles/CasinosStyles';
import TailSpinLoading from '../../common/loading/TailSpinLoading';
import CitySidebar from '../components/casino/CitySidebar';
import AllCasinoList from '../components/casino/AllCasinoList';
import { CasinoContext } from '../../context/CasinoContext';
import { useHttpsStore } from '../../helpers/https';
const CasinoListMap = lazy(() => import('../../common/maps/CasinoListMap'));

const CasinoPage = props =>{
  
  const [isLoading, casinos] = useHttpsStore(
    "https://win-cor.winbet-bg.com/api/casinos",
    "casinosEn",
    "casinosDateEn"
  );
  const [, cities] = useHttpsStore(
    "https://win-cor.winbet-bg.com/api/cities",
    "cityEn",
    "cityDateEn"
  );

  if(isLoading)
    return(
      <TailSpinLoading back="#333"/>
    );
  
  return(
    <CasinoContext.Provider value={{ casinos: casinos, cities: cities, choosenCity: props.match.params.city }}>
      <PageWrapper>
        <div className="container">   
          <ContentWrapper>
            <CitySidebar/>
            <AllCasinoList/>
          </ContentWrapper>
        </div>
      </PageWrapper>
      <Suspense fallback={<TailSpinLoading back="#333"/>}>
        <CasinoListMap
          containerElement={<div style={{ height: `360px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Suspense>
    </CasinoContext.Provider>
  )
}

export default CasinoPage;