import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';

const AppLoading = styled.div`
  width: 100%;
  height: 100vh;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    svg {
      height: 100px;
      width: 100px;
    }
  }
`

const TailSpinLoading = () =>{
  return(
    <>
      <AppLoading>
        <Loader type="TailSpin" color="#2c0f5c"/>
      </AppLoading>
    </>
  );
}

export default TailSpinLoading;