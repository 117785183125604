import React, { useContext } from 'react';
import { MainSliderContext } from '../../../context/MainSliderContext';

const MainSliderContent = () => {

  const  { sliderData }  = useContext(MainSliderContext);
  const imgLink = "https://win-cor.winbet-bg.com/storage/" + sliderData.front_file_name;
  const styles = {};

  styles.button1 = {
    color: sliderData.button_1_text_color,
    backgroundColor: sliderData.button_1_color,
    borderColor: sliderData.button_1_border,
  };
  styles.button2 = {
    color: sliderData.button_2_text_color,
    backgroundColor: sliderData.button_2_color,
    borderColor: sliderData.button_2_border,
  };
  
    if(sliderData.front_file_name === null) {
      return(
        <div className="slider-overlay">
          <div className={sliderData.front_file_description === null ? "overlay-content text-center" : "overlay-content"}>
            <h2>{sliderData.front_file_title}</h2>
            <div className="title-white-line"></div>
            <p>{sliderData.front_file_description} 
            </p>
            <a style={{...styles.button1}} target="_blank" rel="noopener noreferrer" href={sliderData.button_1_bg_url} className={sliderData.button_1_text === null ? 'slider-register-emty' : 'slider-register'}><span>{sliderData.button_1_text}</span></a>
            <a style={{...styles.button2}} target="_blank" rel="noopener noreferrer" href={sliderData.button_2_bg_url} className={sliderData.button_2_text === null ? 'slider-log-in-emty' : 'slider-log-in'}><span>{sliderData.button_2_text}</span></a>
          </div>
        </div>
      )
    }
    return(
      <div className="slider-overlay">
        <div className={sliderData.front_file_description === null ? "overlay-content text-center" : "overlay-content"}>
          <h2>{sliderData.front_file_title}</h2>
          <img className="slider-front-img" src={imgLink} alt="winbet"/>
          <div className="title-white-line"></div>
          <p>{sliderData.front_file_description} 
          </p>
          <a style={{...styles.button1}} target="_blank" rel="noopener noreferrer" href={sliderData.button_1_bg_url} className={sliderData.button_1_text === null ? 'slider-register-emty' : 'slider-register'}><span>{sliderData.button_1_text}</span></a>
          <a style={{...styles.button2}} target="_blank" rel="noopener noreferrer" href={sliderData.button_2_bg_url} className={sliderData.button_2_text === null ? 'slider-log-in-emty' : 'slider-log-in'}><span>{sliderData.button_2_text}</span></a>
        </div>
      </div>
    )
}


export default MainSliderContent;
