import React, { useEffect, useState, useContext } from "react";
import onlineJack from "../../../images/home-slider/sub-onlineJack.png";
import currencyJack from "../../../images/home-slider/currency.png";
import Odometer from 'react-odometerjs'
import { JackpotContext } from '../../../context/JackpotContext';
import { OnlineJackpotBox } from '../../styles/MainSliderStyles';

const OnlineJackpot = () =>{
  const { jackpotData } = useContext(JackpotContext);
  const onlineJackpot = jackpotData.onlinePika ? jackpotData.onlinePika/100 : null;
  const [jackpotValue, setJackpotValue] = useState(onlineJackpot)
  
  useEffect(() =>{
    setJackpotValue(onlineJackpot)
    const interval = setInterval(() => {
      setJackpotValue(onlineJackpot)
    }, 8000);
    return () => clearInterval(interval);
  }, [onlineJackpot])
  
  return(
    <OnlineJackpotBox>
      <div className="main-mystery-box-mega">
        <img className="main-mystery-box-img" src={onlineJack}  alt="winbet online"/>
        <div className="custom-count-online">
          {jackpotValue !== null ?
          (
            <Odometer
              format="( ddd),dd"
              duration={ 1500 }
              value={ jackpotValue }
            />
          ) :
          (
            null
          )
          }
          <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet online"/>
        </div>
      </div>
    </OnlineJackpotBox>
  )
}

export default OnlineJackpot;


