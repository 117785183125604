import React, { useEffect, useState, useContext } from "react";
import raJack from "../../../images/home-slider/sub-jackpot-ra.png";
import versaiJack from "../../../images/home-slider/sub-jackpot-versai.png";
import burningJack from "../../../images/home-slider/sub-jackpot-burning.png";
import currencyJack from "../../../images/home-slider/currency.png";
import Odometer from 'react-odometerjs'
import { JackpotContext } from '../../../context/JackpotContext';
import { ProgressiveJackpotBox } from '../../styles/MainSliderStyles';
import Slider from "react-slick";

const ProgressiveJackpot = () =>{
  const { jackpotData } = useContext(JackpotContext);

  const JackpotRa = jackpotData[1] ? jackpotData[1].ra : null;
  const JackpotVersai = jackpotData[1] ? jackpotData[1].versail : null;
  const JackpotBurning = jackpotData[1] ? jackpotData[1].burning : null; 

  const [RaValue, setRaValue] = useState(JackpotRa);
  const [VersaiValue, setVersaiValue] = useState(JackpotVersai);
  const [BurningValue, setBurningValue] = useState(JackpotBurning);
  
  useEffect(() =>{
    setRaValue(JackpotRa);
    setVersaiValue(JackpotVersai);
    setBurningValue(JackpotBurning);

    const interval = setInterval(() => {
      setRaValue(JackpotRa);
      setVersaiValue(JackpotVersai);
      setBurningValue(JackpotBurning);
    }, 8000);
    return () => clearInterval(interval);
  }, [JackpotRa, JackpotVersai, JackpotBurning])

  var settings = {
    draggable: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 400,
    fade: false,
    cssEase: 'linear',
    dots: false,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1501,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return(
    <ProgressiveJackpotBox>
      <Slider {...settings} className="full-width-slider sub-progressive-slider">
        <div className="slide">
          <div className="main-mystery-box">
            <div className="main-mystery-box-mega">
              <img className="main-mystery-box-img" src={raJack}  alt="winbet rise of ra"/>
              <div className="custom-count-ra">
                {RaValue !== null ?
                  (
                    <Odometer
                      format="(dd ddd),dd"
                      duration={ 1500 }
                      value={ RaValue }
                    />
                  ) :
                  (
                    null
                  )
                }
                <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet rise of ra"/>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="main-mystery-box">
            <div className="main-mystery-box-mega">
              <img className="main-mystery-box-img" src={versaiJack}  alt="winbet versai gold"/>
              <div className="custom-count-versai">
                {VersaiValue !== null ?
                  (
                    <Odometer
                      format="(d ddd),dd"
                      duration={ 1500 }
                      value={ VersaiValue }
                    />
                  ) :
                  (
                    null
                  )
                }
                <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet versai gold"/>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="main-mystery-box">
            <div className="main-mystery-box-mega">
              <img className="main-mystery-box-img" src={burningJack}  alt="winbet burning hot"/>
              <div className="custom-count-burning">
                {BurningValue !== null ?
                (
                  <Odometer
                    format="(d ddd),dd"
                    duration={ 1500 }
                    value={ BurningValue }
                  />
                ) :
                (
                  null
                )
                }
                <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet burning hot"/>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </ProgressiveJackpotBox>
  )
}

export default ProgressiveJackpot;


