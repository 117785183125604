import React, { useContext } from 'react';
import { CasinoListWrapper, CasinoListTitle } from '../../../common/styles/CasinosStyles';
import { CasinoContext } from '../../../context/CasinoContext';
import { Link } from 'react-router-dom';

const CityCasinoList = () =>{

  const { casinos, choosenCity } = useContext(CasinoContext);

  return(
    <>
    {choosenCity !== undefined &&
      <CasinoListWrapper city>
        <CasinoListTitle>
          <h4>{choosenCity}</h4>
          <div className="title-line"></div>
        </CasinoListTitle>
        <ul>
          {casinos && casinos.map((casino, index)=>
          casino.city_name_en === choosenCity &&
            <li key={index}>
              <Link to={`/en/casino/${index+1}`}>
                <div className="content">
                  <img src={`https://win-cor.winbet-bg.com/storage/${casino.profile_img.image_name}`} alt="winbet"/>
                  <div className="adress">{`${casino.city_name_en}, ${casino.address_en}`}</div>
                  <div className="overlay"></div>
                </div>
              </Link>
            </li>
          )}
        </ul>
      </CasinoListWrapper>
    }
    </>
  )


}

export default CityCasinoList;