import styled from 'styled-components';

export const PageWrapper = styled.div`
  background-color: #262626;
  padding: 35px 0 0;
  border-bottom: solid 2px #cd0000;
  @media only screen and (min-width: 1500px){
    .container {
      max-width: 1460px!important;
      padding-left: 20px!important;
      padding-right: 20px!important;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Sidebar = styled.ul`
  box-shadow: 0px -1px 5px #000;
  width: calc(20% - 20px);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  li{
    display: flex;
    align-items: center;
    position: relative;
  }
  a{
    background: linear-gradient(180deg, rgba(45,45,45,1) 0%, rgba(37,37,37,1) 100%);
    display: block;
    color: #808080;
    text-transform: uppercase;
    font-size: 15px;
    padding: 17px 0 17px 30px;
    width: 100%;
    border-bottom: solid 1px #2e2e2e;
    transition: ease-in-out .2s;
  }
  .line{
    position: absolute;
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
    transition: width ease-in-out .2s;
  }
  a.active{
    color: #fff;
  }
  a.active .line, a:hover .line, a:focus .line{
    width: 5px;
  }
  a:hover .line, a:focus .line{
    background-color: #e9e9e9;
  }
  a:hover, a:focus{
    background: linear-gradient(180deg, rgba(222,0,0,1) 0%, rgba(154,0,0,1) 100%);
    color: #fff;
  }
  a.active{
    background: linear-gradient(180deg, rgba(45,45,45,1) 0%, rgba(37,37,37,1) 100%)!important;
  }
  a.active .line{
    background-color: #fe0000;
  }
  @media (max-width: 1500px){
    a{
      font-size: 14px;
    }
  }
  @media (max-width: 1199px){
    a{
      font-size: 13px;
      padding: 15px 0 15px 15px;
    }
    a.active .line, a:hover .line, a:focus .line{
      width: 3px;
    }
  }
`;

export const CasinoListWrapper = styled.div`
  width: 80%;
  ${props => props.city &&`
    width: 100%;
    margin-bottom: 30px;
  `}
  ul{
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px!important;
  }
  li{
    width: 25%;
    padding: 0 5px;
    margin-bottom: 15px;
    position: relative;
  }
  img{
    max-width: 100%;
  }
  .adress{
    font-size: 15px;
    color: #e9e9e9;
    background-color: #313131;
    padding: 3px 10px;
  }
  .content{
    position: relative;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    transition: ease-in-out .3s;
    opacity: 0;
    cursor: pointer;
  }
  .overlay:hover{
    opacity: .3;
  }
  @media (max-width: 1500px){
    li{
      width: 33%;
    }
  }
  @media (max-width: 1199px){
    .adress{
      font-size: 13px;
    }
  }
`;

export const CasinoListTitle = styled.div`
  h4{
    color: #e6e6e6;
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
    margin: 0;
  }
  .title-line{
    width: 100%;
    background: linear-gradient(90deg, rgba(191,188,195,1) 0%, rgba(191,188,195,0) 100%);
    height: 2px;
  }
  ${props => props.mb &&`
    margin-bottom: 15px;
  `}
  ${props => props.secondary &&`
    h4{
      font-size: 18px;
    }
  `}
  @media (max-width: 1199px){
    h4{
      font-size: 18px;
    }
    ${props => props.secondary &&`
      h4{
        font-size: 16px;
      }
    `}
  }
`;

export const SingleCasinoWrapper = styled.div`
  width: 80%;
  margin-bottom: 30px;
  .content-block{
    color: #e5e5e5;
    margin-bottom: 15px;
    font-size: 15px;
  }
  iframe{
    width: 100%;
    height: 400px;
    border: none;
  }
  .text{
    margin-bottom: 30px;
  }
`;

export const SingleCasinoFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SingleCasinoContent = styled.div`
  width: 65%;
  .image-gallery button:focus, .image-gallery button:hover{
    outline: none;
  }
  .image-gallery button:focus:before, .image-gallery button:hover:before{
    color: #fff;
  }
  .image-gallery button:before{
    color: #cc9930;
  }
  .image-gallery-thumbnail.active{
    border: 4px solid #cc9930;
  }
  .image-gallery{
    margin-bottom: 40px;
  }
`;

export const SingleCasinoJackpot = styled.div`
  width: calc(35% - 25px);
  img{
    width: 100%;
    margin-top: 20px;
  }
`;

export const SingleCasinoPromotionList = styled.ul`
  padding-left: 17px!important;
  margin-bottom: 30px!important;
  li{
    position: relative;
    margin-bottom: 7px;
  }
  li:before{
    position: absolute;
    left: -17px;
    top: 0;
    content: '>';
    font-weight: bold;
    font-size: 20px;
    color: #cc9930;
    height: 22px;
    display: flex;
    align-items: center;
  }
`;