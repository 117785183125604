import React, { useContext } from "react";
import { JackpotContext } from '../../../context/JackpotContext';
import MainMega from '../../jackpots/slider/MainMega';
import MainPika from '../../jackpots/slider/MainPika';

const MainJackpot = () =>{
  
  const { jackpotData } = useContext(JackpotContext);
  const checkJackpot = jackpotData[0] ? jackpotData[0] : null;
  
  if(checkJackpot === 'mystery')
  return(
    <>
      <MainMega/>
    </>
  )
  if(checkJackpot !== 'mystery' && checkJackpot !== null)
  return(
    <>
      <MainPika/>
    </>
  )
  return(
    null
  )
}

export default MainJackpot;


