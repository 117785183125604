import React, { useContext } from 'react';
import { CasinoListWrapper, CasinoListTitle } from '../../../common/styles/CasinosStyles'
import { CasinoContext } from '../../../context/CasinoContext';
import CityCasinoList from './CityCasinoList';
import { Link } from 'react-router-dom';

const AllCasinoList = () =>{

  const { casinos } = useContext(CasinoContext);
  
  return(
    <CasinoListWrapper>
      <CityCasinoList/>
      <CasinoListTitle>
        <h4>All Gaming rooms</h4>
        <div className="title-line"></div>
      </CasinoListTitle>
      <ul>
        {casinos && casinos.map((casino, index)=>
          <li key={index}>
            <Link to={`/en/casino/${index+1}`}>
              <div className="content">
                <img src={`https://win-cor.winbet-bg.com/storage/${casino.profile_img.image_name}`} alt="winbet"/>
                <div className="adress">{`${casino.city_name_en}, ${casino.address_en}`}</div>
                <div className="overlay"></div>
              </div>
            </Link>
          </li>
        )}
      </ul>
    </CasinoListWrapper>
  )
}

export default AllCasinoList;