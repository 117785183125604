import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';  
// Context
import { LangContext } from './context/LangContext';
// Helpers
import { useAppLanguage } from './helpers/functions';
// Layouts
import AppRoute from './layouts/AppRoute';
import MainLayout from './layouts/MainLayout';
import HomeLayout from './layouts/HomeLayout';
import EnMainLayout from './layouts/EnMainLayout';
import EnHomeLayout from './layouts/EnHomeLayout';
// BG Pages
import HomePage from './bg/pages/HomePage';
import CasinoPage from './bg/pages/CasinoPage';
import SingleCasinoPage from './bg/pages/SingleCasinoPage';
import ErrorPage from './bg/pages/ErrorPage';
// EN Pages
import HomePageEn from './en/pages/HomePageEn';
import CasinoPageEn from './en/pages/CasinoPageEn';
import SingleCasinoPageEn from './en/pages/SingleCasinoPageEn';
import ErrorPageEn from './en/pages/ErrorPageEn';
// Loading
import TailSpinLoading from './common/loading/TailSpinLoading';
import * as serviceWorker from './serviceWorker';

const AppWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a:hover{
    text-decoration: none;
  }
`

const App = () => {

  const [lang, setLanguageBg, setLanguageEn, isLoading] = useAppLanguage();

  if(isLoading)
    return(
      <TailSpinLoading/>
    );
  return(
    <LangContext.Provider value={{ setLanguageBg: setLanguageBg,  setLanguageEn: setLanguageEn}}>
      <AppWrapper>
        <BrowserRouter>
          {
            lang === 'bg' ?
            (
              <Switch>
                {/*------------------------Pages--------------------*/}
                <AppRoute layout={HomeLayout} exact path="/" component={HomePage} />
                <AppRoute layout={HomeLayout} exact path="/:lng" component={HomePage} />
                <AppRoute layout={MainLayout} exact path="/:lng/casinos" component={CasinoPage} />
                <AppRoute layout={MainLayout} exact path="/:lng/casinos/:city" component={CasinoPage} />
                <AppRoute layout={MainLayout} exact path="/:lng/casino/:id" component={SingleCasinoPage} />
                {/*--------------------------Error--------------------*/}
                <AppRoute layout={MainLayout} component={ErrorPage} />
              </Switch>
            ):
            (
              <Switch>
                {/*------------------------Pages--------------------*/}
                <AppRoute layout={EnHomeLayout} exact path="/" component={HomePageEn} />
                <AppRoute layout={EnHomeLayout} exact path="/:lng" component={HomePageEn} />
                <AppRoute layout={EnMainLayout} exact path="/:lng/casinos" component={CasinoPageEn} />
                <AppRoute layout={EnMainLayout} exact path="/:lng/casinos/:city" component={CasinoPageEn} />
                <AppRoute layout={EnMainLayout} exact path="/:lng/casino/:id" component={SingleCasinoPageEn} />
                {/*--------------------------Error--------------------*/}
                <AppRoute layout={EnMainLayout} component={ErrorPageEn} />
              </Switch>
            )
          }
        </BrowserRouter>
      </AppWrapper>
    </LangContext.Provider>
  );
}

export default App;


ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();