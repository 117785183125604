import React, { lazy, Suspense } from 'react';
import MainSlider from '../components/slider/MainSlider';
import TailSpinLoading from '../../common/loading/TailSpinLoading';
const TopWinsSlider = lazy(() => import('../components/slider/TopWinsSlider'));
const OnlineCasinoBox = lazy(() => import('../components/OnlineCasinoBox'));
const HomeMap = lazy(() => import('../../common/maps/HomeMap'));

const HomePage = () => {
  return(
    <>
      <MainSlider/>
      <Suspense fallback={<TailSpinLoading back="#333"/>}>
        <>
          <TopWinsSlider/>
          <OnlineCasinoBox />
          <HomeMap
            containerElement={<div style={{ height: `360px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </>
      </Suspense>
    </>
  );
}

export default HomePage;