import { useEffect, useState } from 'react';

export const useHttpsStore = (url, store, storeDate) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const date = sessionStorage.getItem(storeDate);
  const promoDate = date && new Date(parseInt(date));
  const now = new Date();
  const dataAge = Math.round((now - promoDate) / (1000*60))
  const tooOld = dataAge >= 5;

  useEffect(() => {
    setData(JSON.parse(sessionStorage.getItem(store)));
    setLoading(false);

    const fetchData = () =>{
      fetch(url, { 
        method: 'get'
      })
      .then(response => (
        response.json())
      )
      .then(data => {
        setLoading(false);
        setData(data);
        sessionStorage.setItem(store, JSON.stringify(data));
        sessionStorage.setItem(storeDate, Date.now());
      })
      .catch(error => {
        console.log(error);
      });
    }
    if (!sessionStorage.getItem(store) || tooOld) {
      fetchData();
    }
  }, [store, storeDate, tooOld, url]);

  return [loading, data];
}


export const useHttpsInterval = (url) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = () =>{
      fetch(url, { 
        method: 'get'
      })
      .then(response => (
        response.json())
      )
      .then(data => {        
        setData(data);
      })
      .catch(error => {
        console.log(error);
      });
    }
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 7000);
    return () => clearInterval(interval);
  }, [url]);

  return [data];
}     