import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return(
    <div className="container">
      <h2>Грешна страница</h2>
      <Link to="/bg/">начало</Link>
      <br/>
      <Link to="/bg/casinos">казина</Link>
    </div>
  )
}

export default ErrorPage;