import React, { useContext } from 'react';
import { MainSliderContext } from '../../../context/MainSliderContext';
import OnlineJackpot from './OnlineJackpot';
import ProgressiveJackpot from './ProgressiveJackpot';
import MainJackpot from './MainJackpot';
import 'odometer/themes/odometer-theme-default.css';
import { JackpotWrapper } from '../../styles/MainSliderStyles';

const JackpotBox = () => {
  const  { sliderData }  = useContext(MainSliderContext);

  return (
    <JackpotWrapper back={sliderData.jackpot_background_color}>
      <MainJackpot/>
      <ProgressiveJackpot/>
      <OnlineJackpot/>
    </JackpotWrapper>
  );
}


export default JackpotBox;
