import React from 'react';
import Header from '../bg/components/header/Header';
import Footer from '../bg/components/footer/Footer';
import styled from 'styled-components';

const HomeLayoutWrapper = styled.div`
  .navbar{
    background: transparent!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 79px;
    padding: 0!important;
  }
`

const HomeLayout = props => {
	
  return (
    <HomeLayoutWrapper>
      <Header/>
      <main>
        {props.children}
      </main>
      <Footer/>
    </HomeLayoutWrapper>
  );
}

export default HomeLayout;