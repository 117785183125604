import React from "react";
import MainMenu from './MainMenu';

const Header = () => {
  return (
    <div id="header">
      <MainMenu />
    </div>
  );
}

export default Header;
