import styled from 'styled-components';

export const LangBarWrapper = styled.div`
  margin-top: -4px;
  .btn-secondary, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
    background-color: transparent!important;
    border: none;
    border-left: solid 1px #5a5987;
    border-radius: 0;
    box-shadow: none;
    color: #e9e9e9;
    font-size: 15px;
  }
  .dropdown-menu{
    background-color: rgb(245, 245, 245);
    padding: 5px 0;
    min-width: 100px;
  }
  .dropdown-item{
    font-size: 15px;
  }
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: #e7e7e8;
    color: #333;
    outline: none;
  }
`;

export const MainMenuWrapper = styled.div`
  .navbar-nav{
    height: 79px;
    padding-top: .5px!important;
  }
  .navbar-brand{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  img{
    max-width: 170px;
  }
  .nav-link{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #e9e9e9;
    font-size: 14px;
    padding: 27px 12px 0!important;
    transition: .2s ease-in-out;
    border-bottom: solid 2px transparent;
    text-transform: uppercase;
  }
  .nav-link:hover{
    color: #fff;
  }
  .nav-link:after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width .3s;
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }
  .nav-link:hover:after, .nav-link.active:after{
    width: 100%;
  }
  .nav-item{
    padding: 0 4px;
  }
  @media only screen and (min-width: 1500px){
    .container {
      max-width: 1400px!important;
      padding-left: 50px!important;
      padding-right: 50px!important;
    }
  }
  @media only screen and (max-width: 1199px){
    .nav-link{
      font-size: 13px;
      padding: 30px 8px 0!important;
    }
    .nav-link:after{
      margin-top: 27px;
    }
    img{
      max-width: 150px;
    }
  }
`;