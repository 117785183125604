import React, { useContext, useState } from 'react';
import { LangContext } from '../../../context/LangContext';
import { LangBarWrapper } from '../../../common/styles/HeaderStyles';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const LangBar = (props) =>{
  
  const { setLanguageBg, setLanguageEn } = useContext(LangContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () =>{
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }
  
  return(
    <LangBarWrapper>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle caret>
          BG
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={setLanguageEn.bind(this, props.history)}>EN</DropdownItem>
          <DropdownItem onClick={setLanguageBg.bind(this, props.history)}>BG</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </LangBarWrapper>
  );
}

export default withRouter(LangBar);