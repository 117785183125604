import React, { useEffect, useState, useContext } from "react";
import splashJack from "../../../images/home-slider/main-jackpot-splash.png";
import heartJack from "../../../images/home-slider/main-jackpot-heart.png";
import diamondJack from "../../../images/home-slider/main-jackpot-diamond.png";
import spadeJack from "../../../images/home-slider/main-jackpot-spade.png";
import megaSubJack from "../../../images/home-slider/sub-jackpot-mega.png";
import currencyJack from "../../../images/home-slider/currency.png";
import Odometer from 'react-odometerjs'
import { JackpotContext } from '../../../context/JackpotContext';
import { MainSliderContext } from '../../../context/MainSliderContext';
import { MainPikaBox, SubMegaBox } from '../../styles/MainSliderStyles';

const MainPika = () =>{
  const { jackpotData } = useContext(JackpotContext);
  const { sliderData } = useContext(MainSliderContext);

  const JackpotMega = jackpotData[1] ? jackpotData[1].mega : null;
  const JackpotSplash = jackpotData[2] ? jackpotData[2].pika : null; 
  const JackpotHeart = jackpotData[2] ? jackpotData[2].cupa : null;
  const JackpotDiamond = jackpotData[2] ? jackpotData[2].karo : null; 
  const JackpotSpade =  jackpotData[2] ? jackpotData[2].spatia : null; 

  const [MegaValue, setMegaValue] = useState(JackpotMega);
  const [SplashValue, setSplashValue] = useState(JackpotSplash);
  const [HeartValue, setHeartValue] = useState(JackpotHeart);
  const [DiamondValue, setDiamondgValue] = useState(JackpotDiamond);
  const [SpadeValue, setSpadegValue] = useState(JackpotSpade);
  
  useEffect(() =>{
    setSplashValue(JackpotSplash);
    setHeartValue(JackpotHeart);
    setDiamondgValue(JackpotDiamond);
    setSpadegValue(JackpotSpade);
    setMegaValue(JackpotMega);

    const interval = setInterval(() => {
      setSplashValue(JackpotSplash);
      setHeartValue(JackpotHeart);
      setDiamondgValue(JackpotDiamond);
      setSpadegValue(JackpotSpade);
      setMegaValue(JackpotMega);
    }, 8000);
    return () => clearInterval(interval);
  }, [JackpotMega, JackpotSplash, JackpotHeart, JackpotDiamond, JackpotSpade])
  
  return(
    <>
      <MainPikaBox border = {sliderData.border_color_slider ? sliderData.border_color_slider : null}>
        <div className="main-jackpotCards-box-splash">
          <img className="main-jackpotCards-box-img" src={splashJack}  alt="winbet jackpotCards"/>
          <div className="main-jackpotCards-box-content">
          <div className="custom-count-splash">
            {SplashValue !== null ?
              (
                <Odometer
                  format="(dd ddd),dd"
                  duration={ 1500 }
                  value={ SplashValue }
                />
              ) : 
              (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
          </div>
        </div>
        <div className="main-jackpotCards-box-heart">
          <img className="main-jackpotCards-box-img" src={heartJack}  alt="winbet jackpotCards"/>
          <div className="custom-count-heart">
            {HeartValue !== null ?
              (
                <Odometer
                  format="( dd ddd),dd"
                  duration={ 1500 }
                  value={ HeartValue }
                />
              ) : (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
        </div>
        <div className="main-jackpotCards-box-diamond">
          <img className="main-jackpotCards-box-img" src={diamondJack}  alt="winbet jackpotCards"/>
          <div className="custom-count-diamond">
            {DiamondValue !== null ?
              (
                <Odometer
                  format="( ddd),dd"
                  duration={ 1500 }
                  value={ DiamondValue }
                />
              ) : 
              (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
        </div>
        <div className="main-jackpotCards-box-spade">
          <img className="main-jackpotCards-box-img" src={spadeJack}  alt="winbet jackpotCards"/>
          <div className="custom-count-spade">
            {SpadeValue !== null ?
              (
                <Odometer
                  format="( ddd),dd"
                  duration={ 1500 }
                  value={ SpadeValue }
                />
              ) :
              (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
        </div>									
	    </MainPikaBox>
      <SubMegaBox>
        <div className="main-mystery-box">
          <div className="main-mystery-box-mega">
            <img className="main-mystery-box-img" src={megaSubJack}  alt="winbet mystery mega"/>
            <div className="custom-count-mega">
              {SpadeValue !== null ?
                (
                  <Odometer
                    format="(dd ddd),dd"
                    duration={ 1500 }
                    value={ MegaValue }
                  />
                ) :
                (
                  null
                )
              }
              <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
            </div>
          </div>
        </div>  
      </SubMegaBox> 
    </>
  )
}

export default MainPika;


