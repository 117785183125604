import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPageEn = () => {
  return(
    <div className="container">
      <h2>Error Page</h2>
      <Link to="/en/">home</Link>
      <br/>
      <Link to="/en/casinos">casinos</Link>
    </div>
  )
}

export default ErrorPageEn;