import React, { useEffect, useState, useContext } from "react";
import mysteryLogo from "../../../images/home-slider/main-jackpot-mystery-logo.png";
import megaJack from "../../../images/home-slider/main-jackpot-mega.png";
import superJack from "../../../images/home-slider/main-jackpot-super.png";
import splashJack from "../../../images/home-slider/main-jackpot-splash.png";
import currencyJack from "../../../images/home-slider/currency.png";
import Odometer from 'react-odometerjs';
import { JackpotContext } from '../../../context/JackpotContext';
import { MainSliderContext } from '../../../context/MainSliderContext';
import { MainMegaBox, SubPikaBox } from '../../styles/MainSliderStyles';

const MainMega = () =>{
  const { jackpotData } = useContext(JackpotContext);
  const { sliderData } = useContext(MainSliderContext);

  const JackpotMega = jackpotData[1] ? jackpotData[1].mega : null;
  const JackpotSuper = jackpotData[1] ? jackpotData[1].super : null;
  const JackpotSplash = jackpotData[2] ? jackpotData[2].pika : null; 

  const [MegaValue, setMegaValue] = useState(JackpotMega);
  const [SuperValue, setSuperValue] = useState(JackpotSuper);
  const [SplashValue, setSplashValue] = useState(JackpotSplash);

  
  useEffect(() =>{
    setMegaValue(JackpotMega);
    setSuperValue(JackpotSuper);
    setSplashValue(JackpotSplash);

    const interval = setInterval(() => {
      setMegaValue(JackpotMega);
      setSuperValue(JackpotSuper);
      setSplashValue(JackpotSplash);
    }, 8000);
    return () => clearInterval(interval);
  }, [JackpotMega, JackpotSuper, JackpotSplash])

  return(
    <>
      <MainMegaBox border = {sliderData.border_color_slider ? sliderData.border_color_slider : null}>
        <img className="main-mystery-box-img-title" src={mysteryLogo}  alt="winbet mystery"/>
        <div className="main-mystery-box-mega">
          <img className="main-mystery-box-img" src={megaJack}  alt="winbet mystery mega"/>
          <div className="custom-count-mega">
            {MegaValue !== null ?
              (
                <Odometer
                    format="(dd ddd),dd"
                    duration={ 1500 }
                    value={ MegaValue }
                />
              ) : (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
        </div>
        <div className="main-mystery-box-super">
          <img className="main-mystery-box-img" src={superJack}  alt="winbet mystery super"/>
          <div className="custom-count-super">
            {SuperValue !== null ?
              (
                <Odometer
                  format="(dd ddd),dd"
                  duration={ 1500 }
                  value={SuperValue }
                />
              ) : 
              (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
        </div>
      </MainMegaBox>
      <SubPikaBox>
        <div className="main-jackpotCards-box-splash">
          <img className="main-jackpotCards-box-img" src={splashJack}  alt="winbet jackpotCards"/>
          <div className="main-jackpotCards-box-content">
          <div className="custom-count-splash">
            {SplashValue !== null ?
              (
                <Odometer
                  format="(dd ddd),dd"
                  duration={ 1500 }
                  value={ SplashValue }
                />
              ) :
              (
                null
              )
            }
            <img className="main-mystery-box-currency" src={currencyJack}  alt="winbet jackpotCards"/>
          </div>
          </div>
        </div>	
      </SubPikaBox>
    </>
  )
}

export default MainMega;


