import { useEffect, useState } from 'react';

export const useAppLanguage = () =>{

  const [lang, setLang] = useState('bg');
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    const url = window.location.href.split("/");
    if(url[3] === 'bg' || url[3] === 'en'){
      setLang(url[3])
    }
    setLoading(false);
  },[]);
  
  const setLanguageBg = (redirect) =>{
  // make current url as array
    let urlArray = redirect.location.pathname.split("/");
  // change lang to bg
    urlArray[1] = 'bg';
  // make it string again
    const currentUrl = urlArray.join();
  // replace commas with slashes
    const editedUrl = currentUrl.replace(/,/g, '/');
  // set state to bg
    setLang('bg');
    // check redirect
    if('/' + lang !== urlArray[1]){
      redirect.push(editedUrl);
    }
  };
  const setLanguageEn = (redirect) =>{
    let urlArray = redirect.location.pathname.split("/");
    urlArray[1] = 'en';
    const currentUrl = urlArray.join();
    const editedUrl = currentUrl.replace(/,/g, '/');
    setLang('en');
    if('/' + lang !== urlArray[1]){
      redirect.push(editedUrl);
    }
  };

  return [lang, setLanguageBg, setLanguageEn, loading]; 
}

export const useCasinoTitleBg = choosenCity =>{
  const [ boxTitle, setBoxTitle ] = useState('');
// Casino list box title for bulgarian lang
  useEffect(() =>{
    switch (choosenCity){
      case "Sofia" :
        return setBoxTitle("София");
      case "Plovdiv" :
        return setBoxTitle('Пловдив');
      case "Varna" :
        return setBoxTitle('Варна');
      case "Burgas" :
        return setBoxTitle('Бургас');
      case "Stara Zagora" :
        return setBoxTitle('Стара Загора');
      case "Ruse" :
        return setBoxTitle('Русе');
      case "Veliko Tarnovo" :
        return setBoxTitle('Велико Търново');
      case "Dobrich" :
        return setBoxTitle('Добрич');
      case "Shumen" :
        return setBoxTitle('Шумен');
      case "Pernik" :
        return setBoxTitle('Перник');
      case "Haskovo" :
        return setBoxTitle('Хасково');
      case "Vratsa" :
        return setBoxTitle('Враца');
      case "Blagoevgrad" :
        return setBoxTitle('Благоевград');
      case "Razgrad" :
        return setBoxTitle('Разград');
      case "Dimitrovgrad" :
        return setBoxTitle('Димитровград');
      default :
        return setBoxTitle('')
    }

  }, [choosenCity])

  return [boxTitle];
}


export const addImageToGallery = (casinos, casinoIndex) =>{
  const imagesArray = casinos[casinoIndex].casino_photo && casinos[casinoIndex].casino_photo;
  const images = [];
  imagesArray.forEach(item => {  
    images.push(
      {
        original: `https://win-cor.winbet-bg.com/storage/${item.image_name}`,
        thumbnail: `https://win-cor.winbet-bg.com/storage/${item.image_name}`
      }
    )
  })
return[ images ]
}